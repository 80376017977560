/* CSS for larger screens */
@media (min-width: 1921px) {
  .drag-drop-item {
    width: 250px;
  }
}

/* CSS for laptop screens */
@media (min-width: 1537px) and (max-width: 1920px) {
  .drag-drop-item {
    width: 250px;
  }
}

/* CSS for smaller screens */
@media (max-width: 1536px) {
  .drag-drop-item {
    width: 200px;
  }
}
